import { ChangeDetectionStrategy, Component, OnInit, ViewChild, NgZone, ChangeDetectorRef } from "@angular/core";
import { Table } from "../../s25-table/Table";
import { Bind } from "../../../decorators/bind.decorator";
import { GenericTableButtonComponent } from "../../s25-table/generics/generic.table.button.component";
import { DocumentListItem, DocumentService } from "../../../services/document.service";
import { ModalService } from "../../modal/modal.service";
import { S25TableComponent } from "../../s25-table/s25.table.component";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { CacheRepository } from "../../../decorators/cache.decorator";
import { Doc } from "./s25.document.const";
import { FlsService } from "../../../services/fls.service";
import { Fls } from "../../../pojo/Fls";
import { ReportService } from "../../../services/report.service";
import { S25Help } from "../../s25-help/s25.help.service";

@TypeManagerDecorator("s25-ng-document-list")
@Component({
    selector: "s25-ng-document-list",
    template: `
        @if (isInit) {
            <div class="top">
                <div class="header">
                    <button
                        class="aw-button aw-button--primary"
                        (click)="onCreateClick()"
                        [disabled]="fls.REP_LIST !== 'F' || isObjectEmpty(defaultFileNames)"
                    >
                        Create Document
                    </button>
                </div>
                @if (fls.REP_LIST !== "F" || isObjectEmpty(defaultFileNames)) {
                    <div class="small c-margin-bottom--single ">
                        Note: You will need the permissions described
                        <a href="{{ documentHelpLink }}" s25Help [helpTopic]="'document'" target="_blank">here</a> in
                        order to create a document.
                    </div>
                }
                <s25-ng-table
                    [dataSource]="tableConfig"
                    [hasRefresh]="true"
                    [hasFilter]="true"
                    [columnSortable]="true"
                ></s25-ng-table>
            </div>
        }
    `,
    styles: `
        .top {
            max-width: 1200px;
        }

        .header {
            display: flex;
            justify-content: space-between;
        }

        .header > button {
            margin-bottom: 1em;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25DocumentListComponent implements OnInit {
    @ViewChild(S25TableComponent) tableComponent: S25TableComponent;

    isInit = false;
    tableConfig: Table.DataSource;
    documents = new Map<number, DocumentListItem>();
    eventReference: string;
    fls: Fls;
    defaultFileNames: Record<Doc.ScopeReportId, string>;
    documentHelpLink = S25Help.getHelpUrl("document");

    constructor(
        private zone: NgZone,
        private cd: ChangeDetectorRef,
    ) {}

    async ngOnInit() {
        this.fls = await FlsService.getFls();
        // get document scope  list, if none,  create doucment button disable
        this.defaultFileNames = await ReportService.getDefaultDocumentFileNames();
        this.initTableConfig();

        this.isInit = true;
        this.cd.detectChanges();
    }

    isObjectEmpty(obj: any): boolean {
        return Object.keys(obj).length === 0;
    }

    initTableConfig() {
        this.tableConfig = {
            type: "unpaginated",
            dataSource: this.getRows,
            columns: [
                {
                    id: "name",
                    header: "Name",
                },
                {
                    id: "scope",
                    header: "Scope",
                },
                GenericTableButtonComponent.Column("View", this.onViewClick, "outline"),
                GenericTableButtonComponent.Column("Copy", this.onCopyClick, "outline"),
                GenericTableButtonComponent.Column("Edit", this.onEditClick, "outline"),
                GenericTableButtonComponent.Column("Delete", this.onDeleteClick, "danger--outline"),
            ],
        };
    }

    @Bind
    async getRows(query: Table.UnpaginatedQuery): Promise<Table.DataSourceResponse> {
        if (query.forceRefresh) CacheRepository.invalidateByService("DocumentService", "getDocumentList");

        const documents = await DocumentService.getDocumentList();
        for (let doc of documents) {
            this.documents.set(doc.itemId, doc);
            doc.repList = this.fls.REP_LIST;
        }

        return {
            rows: documents.map(this.mapToRow),
        };
    }

    mapToRow(doc: DocumentListItem): Table.Row {
        return {
            id: doc.itemId,
            name: doc.itemName,
            cells: {
                name: { text: doc.itemName },
                scope: { text: Doc.scope[doc.type]?.label || doc.type },
                header: { text: "header" },
                copy: {
                    inputs: {
                        label: "Copy",
                        type: "outline",
                        disabled: doc.accessLevel !== "F" || doc.repList !== "F",
                    },
                },
                edit: {
                    inputs: {
                        label: "Edit",
                        type: "outline",
                        disabled: doc.accessLevel === "R" || doc.repList === "R",
                    },
                },
                delete: {
                    inputs: {
                        label: "Delete",
                        type: "danger--outline",
                        disabled: doc.accessLevel !== "F" || doc.repList !== "F",
                    },
                },
            },
        };
    }

    onCreateClick() {
        ModalService.modal("edit-document", {
            mode: "create",
            title: `Create Document`,
            repList: this.fls.REP_LIST,
            onSave: this.tableComponent.refresh,
        });
    }

    @Bind
    onEditClick(row: Table.Row) {
        ModalService.modal("edit-document", {
            id: row.id,
            mode: "edit",
            repList: this.fls.REP_LIST,
            title: `Edit Document: ${row.name}`,
            onSave: this.tableComponent.refresh,
        });
    }

    @Bind
    onViewClick(row: Table.Row) {
        ModalService.modal("edit-document", {
            id: row.id,
            mode: "view",
            repList: this.fls.REP_LIST,
            title: `View Document: ${row.name}`,
            onSave: this.tableComponent.refresh,
        });
    }

    @Bind
    onCopyClick(row: Table.Row) {
        ModalService.modal("edit-document", {
            id: row.id,
            mode: "copy",
            repList: this.fls.REP_LIST,
            title: `Copy Document: ${row.name}`,
            onSave: this.tableComponent.refresh,
        });
    }

    @Bind
    async onDeleteClick(row: Table.Row) {
        let dialogData = ModalService.dialogType(
            "Yes No",
            {
                message: "Are you sure you want to delete this document?",
                title: "Confirm Deletion",
            },
            "No",
        );
        await ModalService.modal("dialog", dialogData);
        if (dialogData.answer !== 1) return; // User answered no

        const [_, error] = await S25Util.Maybe(DocumentService.deleteDocument(row.id as number));
        if (error) return S25Util.showError(error);
        return this.tableComponent.refresh();
    }
}

import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { Table } from "../../s25-table/Table";
import { S25TableComponent } from "../../s25-table/s25.table.component";
import { GenericTableButtonComponent } from "../../s25-table/generics/generic.table.button.component";
import { Bind } from "../../../decorators/bind.decorator";
import { Blackout, SpaceService } from "../../../services/space.service";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { UserprefService } from "../../../services/userpref.service";
import { S25Datefilter } from "../../s25-dateformat/s25.datefilter.service";
import { S25ModalComponent } from "../../s25-modal/s25.modal.component";
import { GenericTableFadePreviewComponent } from "../../s25-table/generics/generic.table.fade.preview.component";
import { GenericTableRenderHTMLComponent } from "../../s25-table/generics/generic.table.render.html.component";

@TypeManagerDecorator("s25-ng-blackout-management-list")
@Component({
    selector: "s25-ng-blackout-management-list",
    template: `
        @if (isInit) {
            <div class="top">
                <h2 class="c-margin-bottom--single">Blackouts</h2>
                <div class="header c-margin-bottom--single">
                    <button class="aw-button aw-button--primary" (click)="onCreateClick()">Add Blackout</button>
                </div>
                <s25-ng-table
                    [dataSource]="tableData"
                    [hasRefresh]="true"
                    [hasColumnChooser]="true"
                    [columnSortable]="true"
                    [stickyColumnSortPreference]="'blackout'"
                    [showHeaderWhenNoData]="true"
                >
                    <ng-template #tableHeader>
                        <div class="flexRow">
                            <s25-ng-editable-date [alwaysEditing]="true" [(val)]="startDate"></s25-ng-editable-date>
                            <div [style.margin]="'auto'">to</div>
                            <s25-ng-editable-date [alwaysEditing]="true" [(val)]="endDate"></s25-ng-editable-date>
                            <button class="aw-button aw-button--primary" (click)="tableComponent.refresh()">
                                Search
                            </button>
                        </div>
                    </ng-template>
                </s25-ng-table>
                <s25-ng-modal #editBlackoutModal [title]="chosenBlackout?.title" [size]="'xs'">
                    <ng-template #s25ModalBody>
                        <s25-ng-edit-blackout
                            #editBlackout
                            [isNew]="chosenBlackout.isNew"
                            [blackout]="chosenBlackout.model"
                            (saved)="tableComponent.refresh(true)"
                            (close)="closeEditModal()"
                        ></s25-ng-edit-blackout>
                    </ng-template>
                </s25-ng-modal>
                <s25-ng-modal #deleteBlackoutModal [title]="'Confirm Deletion'" [type]="'deny'" [size]="'xs'">
                    Are you sure that you want to delete this blackout?
                </s25-ng-modal>
            </div>
        }
    `,
    styles: `
        .flexRow {
            display: flex;
            gap: 0.5em;
        }

        ::ng-deep s25-ng-blackout-management-list .form-group {
            margin-bottom: 0 !important;
        }

        :host ::ng-deep .s25-modal--body {
            max-height: 45em;
        }
    `,
})
export class S25BlackoutManagementListComponent implements OnInit {
    isInit = false;
    tableData: Table.DataSource;
    blackouts = new Map<string, Blackout>();
    chosenBlackout: { isNew: boolean; title: string; model: Blackout };
    startDate: Date;
    endDate: Date;
    dateTimeFormat: string;

    @ViewChild(S25TableComponent) tableComponent: S25TableComponent;
    @ViewChild("editBlackoutModal") editBlackoutModal: S25ModalComponent;
    @ViewChild("deleteBlackoutModal") deleteBlackoutModal: S25ModalComponent;

    constructor(private changeDetector: ChangeDetectorRef) {}

    async ngOnInit() {
        this.dateTimeFormat = await UserprefService.getS25DateTimeformat();
        this.startDate = new Date();
        this.endDate = S25Util.date.addMonths(S25Util.date.currentDate(), 1);
        this.tableData = this.getTableConfig();
        this.isInit = true;
        this.changeDetector.detectChanges();
    }

    getTableConfig(): Table.DataSource {
        return {
            type: "unpaginated",
            dataSource: this.getRows,
            columns: [
                {
                    id: "blackout",
                    header: "Blackout",
                },
                {
                    id: "profile",
                    header: "Pattern",
                },
                {
                    id: "start",
                    header: "Start",
                },
                {
                    id: "end",
                    header: "End",
                },
                {
                    id: "notes",
                    header: "Notes",
                    content: { component: GenericTableFadePreviewComponent },
                },
                GenericTableButtonComponent.Column("Copy", this.onCopyClick, "outline"),
                GenericTableButtonComponent.Column("Edit", this.onEditClick, "outline"),
                GenericTableButtonComponent.Column("Delete", this.onDeleteClick, "danger--outline"),
            ],
        };
    }

    async getData() {
        const start = S25Util.date.toS25ISODateStrStartOfDay(this.startDate);
        const end = S25Util.date.toS25ISODateStrEndOfDay(this.endDate);
        const data = await SpaceService.getBlackouts(`?all_blackouts=1&start_dt=${start}&end_dt=${end}`);
        return data.blackout;
    }

    @Bind
    async getRows(query: Table.UnpaginatedQuery): Promise<Table.DataSourceResponse> {
        const blackouts = (await this.getData()) || [];
        for (let blackout of blackouts) this.blackouts.set(blackout.profile_name, blackout);

        return {
            rows: blackouts.map(this.mapToRow),
        };
    }

    @Bind
    mapToRow(item: Blackout): Table.Row {
        return {
            id: item.profile_name,
            name: item.profile_name,
            cells: {
                blackout: { text: item.profile_name },
                profile: { text: item.profile_code },
                start: {
                    text: S25Datefilter.transform(item.init_start_dt, this.dateTimeFormat),
                    sortValue: S25Util.date.toS25ISODateTimeStr(item.init_start_dt),
                },
                end: {
                    text: S25Datefilter.transform(item.date[item.date.length - 1].end_dt, this.dateTimeFormat),
                    sortValue: S25Util.date.toS25ISODateTimeStr(item.date[item.date.length - 1].end_dt),
                },
                notes: {
                    inputs: {
                        cell: { component: GenericTableRenderHTMLComponent, inputs: { text: item.comment || "" } },
                    },
                },
            },
        };
    }

    async onCreateClick() {
        const start = S25Util.date.toS25ISODateTimeStr(S25Util.date.toStartOfHour(new Date()));
        const end = S25Util.date.toS25ISODateTimeStr(S25Util.date.addHours(S25Util.date.toStartOfHour(new Date()), 1));
        this.chosenBlackout = {
            isNew: true,
            title: "Create New Blackout",
            model: {
                profile_name: "New Blackout",
                init_start_dt: start,
                init_end_dt: end,
                date: [{ start_dt: start, end_dt: end }],
            } as unknown as Blackout,
        };
        await this.editBlackoutModal.open();
    }

    @Bind
    async onCopyClick(row: Table.Row) {
        const blackout = this.blackouts.get(row.id as string);
        this.chosenBlackout = { isNew: true, title: `Copy Blackout: ${blackout.profile_name}`, model: blackout };
        blackout.profile_name += " copy";
        await this.editBlackoutModal.open();
    }

    @Bind
    async onEditClick(row: Table.Row) {
        const blackout = this.blackouts.get(row.id as string);
        this.chosenBlackout = { isNew: false, title: `Edit Blackout: ${blackout.profile_name}`, model: blackout };
        await this.editBlackoutModal.open();
    }

    @Bind
    async onDeleteClick(row: Table.Row) {
        const yes = await this.deleteBlackoutModal.open();
        if (yes) {
            const blackout = this.blackouts.get(row.id as string);
            await SpaceService.deleteBlackout(
                blackout.profile_name,
                blackout.profile_code,
                blackout.init_start_dt,
                blackout.init_end_dt,
            );
            await this.tableComponent.refresh();
        }
    }

    async closeEditModal() {
        await this.editBlackoutModal.close();
    }
}
